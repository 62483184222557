import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Testimonials from "../components/Testimonials";
import { CallToAction } from "../components/CallToAction";
import Faqs from "../components/Faqs";
import { Helmet } from "react-helmet";

export const Base = () => {
  return (
    <Layout>
      <Helmet>
        <title>Get online fast without spending a fortune | Sixvees</title>
        <meta
          name="description"
          content="The perfect starting point for any business looking for a simple and effective website. Includes all of the necessary features to put your business on the map."
        ></meta>
      </Helmet>
      <main>
        <div className="page-header sr-only">
          <h1>Base</h1>
        </div>

        <section className="primary-content">
          <div className="primary-content__content">
            <div className="container">
              <h2>Start with a solid base</h2>
              <p>
                Are you a new business? Or maybe new to the web? Find out what a
                high-quality website can do for you with our Base website
                package.
              </p>
              <p>
                It is important to have an online presence so that potential
                customers know who you are and what you do. This package allows
                you to get online fast without spending a fortune.
              </p>
            </div>
          </div>
          <div className="icon-scroll" style={{ opacity: 1 }} />
        </section>

        <section className="bg-light text-dark pt-0 dark-strip-top">
          <div className="container">
            <h2 className="heading-sm mb-5 text-white-stroke">
              Base website features
            </h2>
            <div className="row justify-content-between pt-4 mb-4">
              <div className="col-lg-5 ">
                <img
                  src="/images/102031838_m-e1572223598501-575x833.jpg"
                  alt="Popsicles"
                  className="img-fluid product"
                />
              </div>
              <div className="pt-4 pt-lg-0 col-lg-6 d-flex align-items-center">
                <div>
                  <div className="lead mb-4">
                    <p>
                      The perfect starting point for any business looking for
                      something simple and effective. It includes all of the
                      necessary features to put your business on the map.
                    </p>
                  </div>
                  <ul className="fa-ul ml-4 mb-5 lead">
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Professional one page scrolling design
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Looks great on all screen sizes
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Secure with low maintenance required
                    </li>

                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Search Engine Optimised
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Google Analytics for visitor reporting and website
                      statistics
                    </li>
                  </ul>
                  <h3 className="mb-5">$1200</h3>
                  <Link
                    to="/get-in-touch/"
                    className="btn btn-outline-success mt-3"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faqs />
        <Testimonials />
        <CallToAction text="No sure yet? Meet with us for free coffee and advice." />
      </main>
    </Layout>
  );
};

export default Base;
